.hgContainer .button-hg {
  background: #F49536;
  border-radius: 5px;
}

.hgContainer #promoCode {
color: #000 !important;
}

.mbsc-ios.mbsc-btn-primary.mbsc-btn {
  background: #22B6C1;
}

.btn-book-hg {
  color: #FFFFFF;
}

.hgContainer h5, .hgContainer .h5 {
  font-size: 18px;
  margin: 0;
}

.hgContainer h6, .hgContainer .h6 {
  color: #FFFFFF !important;
  margin-bottom: 0;
}

.header-dates-modalRate {
  background-color: rgb(34 182 193 / 0.3);
}

.mbsc-ios.mbsc-segmented .mbsc-segmented-secondary .mbsc-segmented-content {
  background-color: rgb(34 182 193 / 0.3);
}

.mbsc-ios.mbsc-segmented .mbsc-segmented-secondary.mbsc-segmented-item input:checked + .mbsc-segmented-content {
  color: #ffffff;
  background: #22B6C1;
}

.variable-color {
  background: #22B6C1 !important;
}

.image_modalboard_background {
  height: 615px;
}

.ribbon-hg-white {
  background: #F49536;
}

.mbsc-ios-dark.mbsc-calendar-button.mbsc-button, .mbsc-ios-dark.mbsc-button-flat {
color: #22B6C1;
}

.mbsc-ios-dark.mbsc-range-control-value.active {
  color: #22B6C1;
}

.mbsc-ios-dark.mbsc-calendar-month-title {
  color: #22B6C1;
}

.mbsc-ios-dark.mbsc-selected .mbsc-calendar-cell-text {
  border-color: #F49536;
  background: #F49536;
  color: #fff;
}