.icons {
  width: 130px;
}

#tel {
  width: 130px;
}

.icons > img {
  width: 70px;
  margin: auto;
}

.icons > p {
  width: 130px;
  margin: 10px auto 5px auto;
  text-align: center;
  font-weight: 800;
  font-size: 15px;
  line-height: 15px;
}

.espacios-izquierda {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  > img {
    border-radius: 50px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  > div {
    width: 100%;
    border-radius: 50px;
    padding: 20px 30px 20px 30px;
    background-color: rgb(244 149 54 / 0.3);
    > h5 {
      font-weight: 800;
      font-size: 24px;
    }
    > p {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.espacios-derecha {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  > img {
    border-radius: 50px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  > div {
    width: 100%;
    border-radius: 50px;
    padding: 20px 30px 20px 30px;
    background-color: rgb(244 149 54 / 0.3);
    > h5 {
      font-weight: 800;
      font-size: 24px;
    }
    > p {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.servicios-izquierda {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  > img {
    border-radius: 50px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  > div {
    width: 100%;
    border-radius: 50px;
    padding: 20px 30px 20px 30px;
    background-color: rgb(34 182 193 / 0.2);
    > h5 {
      font-weight: 800;
      font-size: 24px;
    }
    > p {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.servicios-derecha {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;
  > img {
    border-radius: 50px;
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  > div {
    width: 100%;
    border-radius: 50px;
    padding: 20px 30px 20px 30px;
    background-color: rgb(34 182 193 / 0.2);
    > h5 {
      font-weight: 800;
      font-size: 24px;
    }
    > p {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

@media (min-width: 768px) {
  .icons {
    width: 180px;
  }
  #tel {
    width: 170px;
  }
  .espacios-izquierda {
    flex-direction: row;
    > img {
      width: 35%;
      height: auto;
      margin: 0;
    }
    > div {
      width: 60%;
      padding: 45px;
      > h5 {
        font-size: 30px;
      }
      > p {
        font-size: 24px;
        line-height: 25px;
      }
    }
  }

  .espacios-derecha {
    flex-direction: row-reverse;
    > img {
      width: 35%;
      height: auto;
      margin: 0;
    }
    > div {
      width: 60%;
      padding: 45px;
      > h5 {
        font-size: 30px;
      }
      > p {
        font-size: 24px;
        line-height: 25px;
      }
    }
  }

  .servicios-izquierda {
    flex-direction: row;
    > img {
      width: 35%;
      height: auto;
      margin: 0;
    }
    > div {
      width: 60%;
      padding: 45px;
      > h5 {
        font-size: 30px;
      }
      > p {
        font-size: 24px;
        line-height: 25px;
      }
    }
  }

  .servicios-derecha {
    flex-direction: row-reverse;
    > img {
      width: 35%;
      height: auto;
      margin: 0;
    }
    > div {
      width: 60%;
      padding: 45px;
      > h5 {
        font-size: 30px;
      }
      > p {
        font-size: 24px;
        line-height: 25px;
      }
    }
  }
}

@media (min-width: 1100px) {
  .espacios-izquierda {
    > img {
      height: 230px;
    }
  }
  .espacios-derecha {
    > img {
      height: 230px;
    }
  }
  .servicios-izquierda {
    > img {
      height: 230px;
    }
  }
  .servicios-derecha {
    > img {
      height: 230px;
    }
  }
}
