li::marker {
  color: #f49536;
}

.salas-izquierda {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(244 149 54 / 0.3);
  margin-top: 20px;
  border-radius: 50px;
  > img {
    border-radius: 50px;
    width: 90%;
    height: 180px;
    object-fit: cover;
    object-position: center;
    margin: 10px auto;
  }
  > div {
    width: 100%;
    padding: 20px 30px 0px 30px;

    > h5 {
      font-weight: 800;
      font-size: 24px;
    }
    > ul {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.salas-derecha {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: rgb(244 149 54 / 0.3);
  margin-top: 20px;
  border-radius: 50px;
  > img {
    border-radius: 50px;
    width: 90%;
    height: 180px;
    object-fit: cover;
    object-position: center;
    margin: 10px auto;
  }
  > div {
    width: 100%;
    padding: 20px 30px 0px 30px;
    > h5 {
      font-weight: 800;
      font-size: 24px;
    }
    > ul {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

#carrousel {
  button {
    svg {
      path {
        color: #f49536;
      }
    }
  }
}

@media (min-width: 768px) {
  .icons {
    width: 180px;
  }
  #tel {
    width: 170px;
  }
  .salas-izquierda {
    flex-direction: row;
    min-height: 220px;
    > img {
      width: 35%;
      height: full;
      margin: auto;
    }
    > div {
      width: 60%;
      padding: 45px;
      margin: auto 0;
      > h5 {
        font-size: 30px;
      }
    }
  }

  .salas-derecha {
    flex-direction: row-reverse;
    min-height: 220px;
    > img {
      width: 35%;
      height: full;
      margin: auto;
    }
    > div {
      margin: auto 0;
      width: 60%;
      padding: 45px;
      > h5 {
        font-size: 30px;
      }
    }
  }
}

@media (min-width: 1100px) {
  .salas-izquierda {
    min-height: 280px;
    > img {
      height: 230px;
    }
    > div {
      > ul {
        font-size: 24px;
        line-height: 25px;
      }
    }
  }
  .salas-derecha {
    min-height: 280px;
    > img {
      height: 230px;
    }
    > div {
      > ul {
        font-size: 24px;
        line-height: 25px;
      }
    }
  }
}
